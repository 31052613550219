'use client';

import React, { MouseEventHandler, useState, useEffect } from 'react';
import Link from 'next/link';
import Image from 'next-image-export-optimizer';
import { IconMenu2, IconCaretDownFilled, IconX } from '@tabler/icons-react';
import { useClickOutside } from '@mantine/hooks';
import { usePathname } from 'next/navigation';
import GoogleTagManager from '@dr-pam/common-components/Components/Page/GoogleTagManager';

export type HeaderProps = {
	gtmId: string;
};

export default function Header({ gtmId }: HeaderProps) {
	const [navEl, setNavEl] = useState<HTMLElement | null>(null);
	const [menuEl, setMenuEl] = useState<HTMLElement | null>(null);
	const [isAboutDropdownOpen, setIsAboutDropdownOpen] = useState<boolean>(false);
	const [isClinicDropdownOpen, setIsClinicDropdownOpen] = useState<boolean>(false);
	const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

	const pathname = usePathname();
	const isHomePage = pathname === '/';

	const handleMenuClicked: MouseEventHandler<HTMLAnchorElement> = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setIsMenuOpen((prev) => !prev);
		setIsAboutDropdownOpen(false);
		setIsClinicDropdownOpen(false);
	};

	const handleAboutDropdownToggle: MouseEventHandler<HTMLAnchorElement> = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setIsAboutDropdownOpen((prev) => !prev);
		setIsClinicDropdownOpen(false);
	};

	const handleClinicDropdownToggle: MouseEventHandler<HTMLAnchorElement> = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setIsClinicDropdownOpen((prev) => !prev);
		setIsAboutDropdownOpen(false);
	};

	const handleLinkClick: MouseEventHandler<HTMLAnchorElement> = () => {
		setIsMenuOpen(false);
		setIsAboutDropdownOpen(false);
		setIsClinicDropdownOpen(false);
	};

	useClickOutside(
		() => {
			setIsMenuOpen(false);
			setIsAboutDropdownOpen(false);
			setIsClinicDropdownOpen(false);
		},
		null,
		[navEl, menuEl],
	);

	useEffect(() => {
		if (isMenuOpen) {
			document.body.classList.add('menu-open');
		} else {
			document.body.classList.remove('menu-open');
		}
	}, [isMenuOpen]);

	return (
		<>
			<GoogleTagManager gtmId={gtmId} />
			<header className={isHomePage ? 'transparent-header' : 'dark-bg'}>
				<Link href="/" className="header-logo drpam-logo no-underline">
					<Image
						src="/images/logos/dr-pam_logo_full-white.webp"
						alt="Dr Pam logo"
						priority={true}
						width={50}
						height={50}
						unoptimized
					/>
				</Link>
				<nav ref={setNavEl} className={`switch-link-underline-direction ${isMenuOpen ? 'menu-open' : ''}`}>
					<Link href="#about" onClick={handleAboutDropdownToggle} className="dropdown-toggle no-underline">
						about possums/<span className="capitalize-ndc">ndc</span> <IconCaretDownFilled />
					</Link>
					{isAboutDropdownOpen && (
						<div className="dropdown-menu">
							<Link href="/about-ndc-possums#about" onClick={handleLinkClick} className="no-underline">
								<span className="only-mobile">01</span> what is possums/ndc?
							</Link>
							<Link
								href="/about-ndc-possums#our-programs"
								onClick={handleLinkClick}
								className="no-underline"
							>
								<span className="only-mobile">02</span> our programs
							</Link>
							<Link href="/about-ndc-possums#research" onClick={handleLinkClick} className="no-underline">
								<span className="only-mobile">03</span> the research
							</Link>
							<Link
								href="/about-ndc-possums#testimonials"
								onClick={handleLinkClick}
								className="no-underline"
							>
								<span className="only-mobile">04</span> testimonials
							</Link>
						</div>
					)}
					<Link href="/free-resources" onClick={handleLinkClick}>
						free resources
					</Link>
					<Link href="/evidence-base" onClick={handleLinkClick}>
						ndc evidence base
					</Link>
					<Link href="/books-by-drpam" onClick={handleLinkClick}>
						dr pam&apos;s books
					</Link>
					<Link href="#clinic" onClick={handleClinicDropdownToggle} className="dropdown-toggle no-underline">
						clinic <IconCaretDownFilled />
					</Link>
					{isClinicDropdownOpen && (
						<div className="dropdown-menu">
							<Link href="/clinic#clinic-drpam" onClick={handleLinkClick} className="no-underline">
								<span className="only-mobile">01</span> consult with dr pam
							</Link>
							<Link
								href="/clinic#accredited-practitioner"
								onClick={handleLinkClick}
								className="no-underline"
							>
								<span className="only-mobile">02</span> consult with an ndc accredited practitioner
							</Link>
						</div>
					)}
					<Link href="/blog" onClick={handleLinkClick}>
						blog
					</Link>
					{/* <Link href="/media" onClick={handleLinkClick}>
						media
					</Link> */}
				</nav>
				<a ref={setMenuEl} href="#" className="menu-toggle no-underline" onClick={handleMenuClicked}>
					{isMenuOpen ? <IconX /> : <IconMenu2 />}
				</a>
			</header>
		</>
	);
}
